<template>
    <div class="login">
        <div class="wrapper">
            <div class="logo">
                <img src="images/logo_white.svg" alt=""/>
                <span>VOLL:KONTAKT</span>
            </div>
            <form v-on:submit.prevent="">
                <base-input-floatinglabel v-model="email" type="text" placeholder="E-Mail" icon="envelope"/>
                <base-input-floatinglabel v-model="password" type="password" placeholder="Passwort" icon="key"/>

              <BaseButton :loading="auth.pending"
                          :disabled="checkForm || auth.pending"
                          icon="sign-in-alt"
                          label="Anmelden"
                          :submit="true"
                          @click="send"/>
            </form>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      email: null,
      password: null
    }
  },
  methods: {
    async send () {
      await this.login({
        email: this.email,
        password: this.password
      })

      if (this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect)
      } else {
        this.$router.push({ name: 'dashboard' })

        this.$toasted.show('test')
      }
    },
    ...mapActions({ login: 'auth/login' })
  },
  computed: {
    validEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.email)
    },
    checkForm () {
      return !(this.validEmail && this.password) || this.auth.pending
    },
    ...mapState(['app', 'auth'])
  }
}
</script>

<style lang="scss">

    .login::after {
        content: "";
        display: block;
        width: 100%;
        background-color: $primary-color;
        height: 50vh;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }

    .login .wrapper {
        position: absolute;
        width: 280px;
        height: 300px;
        top: 50%;
        margin-top: -150px;
        right: 50%;
        margin-right: -140px;
    }

    .login .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
        font-size: 1.5rem;
    }

    .login .logo img {
        max-width: 24px;
        width: 100%;
        margin-right: 6px;
    }

    .login .logo span {
        text-transform: uppercase;
        font-weight: 700;
        color: $white;
    }

    .login form {
        background-color: $white;
        padding: 32px 24px;
        box-shadow: 0 0 10px $text-color-4;
        border-radius: 10px;
    }

    .login .background {
        margin: 0 auto;
        padding: 10px 0;
        opacity: 0.1;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .login button {
        margin: 10px auto 0 auto;
        display: flex;
    }

    #app.dark {
        .login {
            background-color: $black-1;
        }

        .login::after {
            background-color: $black-2;
        }

        .login form {
            background-color: $gray-900;
        }

        .login .logo span {
            color: $white;
        }

    }

</style>
